module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.0_gatsby@5.13.1_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"cy7.io","short_name":"cy7.io","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e15bf9b7ce53a5a3c49c52cd7b4df0ea"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@5.13.0_gatsby@5.13.1_react-dom@18.2.0_react-typography@0.16.23_react@18.2.0_typography@0.16.24/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/@cy7/typography/index.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.1_@swc+core@1.3.102_babel-eslint@10.1.0_esbuild@0.18.20_eslint-plugin-jest@24.7.0_3cg6nmhxvgdnlfcgjscfejp44m/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
